import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [assignments, setAssignments] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const [currentDay, setCurrentDay] = useState('');
  const [availableTimes, setAvailableTimes] = useState([{ start: '8:00 AM', end: '8:00 PM' }]);
  const [rectangles, setRectangles] = useState([]);
  const [allDayEvents, setAllDayEvents] = useState([]);
  const [mealsAndShowers, setMealsAndShowers] = useState([]);

  // Convert time to minutes
  const timeToMinutes = (time) => {
    const [timePart, period] = time.split(' ');
    const [hours, minutes] = timePart.split(':').map(Number);
    let totalMinutes = hours * 60 + minutes;

    if (period === 'PM' && hours !== 12) {
        totalMinutes += 12 * 60;
    }
    if (period === 'AM' && hours === 12) {
        totalMinutes -= 12 * 60;
    }

    return totalMinutes;
  };  

  // Convert minutes to time string
  const minutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';
    const hour12 = hours % 12 || 12;
    return `${hour12}:${mins < 10 ? '0' : ''}${mins} ${period}`;
  };

  // Update available times after placing an event/class
  const updateAvailableTimes = (times, start, end) => {
    const startMinutes = timeToMinutes(start);
    const endMinutes = timeToMinutes(end);

    const updatedTimes = [];

    times.forEach((timeSlot) => {
      const slotStartMinutes = timeToMinutes(timeSlot.start);
      const slotEndMinutes = timeToMinutes(timeSlot.end);

      if (endMinutes <= slotStartMinutes || startMinutes >= slotEndMinutes) {
        updatedTimes.push(timeSlot);
      } else {
        if (startMinutes > slotStartMinutes) {
          updatedTimes.push({
            start: minutesToTime(slotStartMinutes),
            end: minutesToTime(startMinutes),
          });
        }
        if (endMinutes < slotEndMinutes) {
          updatedTimes.push({
            start: minutesToTime(endMinutes),
            end: minutesToTime(slotEndMinutes),
          });
        }
      }
    });
    return updatedTimes;
  };

  const processData = (classes, events, mealsAndShowers) => {
    let rects = [];
    let updatedAvailableTimes = [...availableTimes];
    let allDayEvents = [];
  
    // Process classes
    classes.forEach(item => {
      rects.push({
        type: 'class',
        class: item.class,
        start: item.start,
        end: item.end,
      });
      updatedAvailableTimes = updateAvailableTimes(updatedAvailableTimes, item.start, item.end);
    });
  
    // Process events
    events.forEach(item => {
      if ((item['Column_F'] || '').toLowerCase().includes('all day')) {
        allDayEvents.push({
          event: item['Column_B'],
          start: item['Column_C'],
          end: item['Column_D'],
          location: item['Column_E'],
          description: item['Column_F'],
        });
      } else {
        rects.push({
          type: 'event',
          calendar: item['Column_A'],
          event: item['Column_B'],
          start: item['Column_C'],
          end: item['Column_D'],
          location: item['Column_E'], 
          description: item['Column_F'],
        });
        // Update available times
        updatedAvailableTimes = updateAvailableTimes(updatedAvailableTimes, item['Column_C'], item['Column_D']);
      }
    });
  
    // Sort by start time
    rects.sort((a, b) => timeToMinutes(a.start) - timeToMinutes(b.start));
  
    // Update the rectangles state
    setRectangles(rects);
    setAvailableTimes(updatedAvailableTimes);
    setAllDayEvents(allDayEvents);

  };
  
  useEffect(() => {
    // Set Day of the Week
    const date = new Date();
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayofWeek = daysOfWeek[date.getDay()];
    setCurrentDay(dayofWeek);
  
    // Get the current date in MM/DD/YYYY format
    const today = new Date();
    const formattedDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
    setCurrentDate(formattedDate);
  
    const fetchDataAndUpdateRectangles = async () => {
      try {
        const classesResponse = await fetch('https://dasevu01s7.execute-api.us-east-1.amazonaws.com/FirstLaunch/classes');
        const classesData = await classesResponse.json();
        console.log('Fetched Classes Response:', classesData);

        const parsedData = JSON.parse(classesData.body);

        console.log('Parsed Data:', parsedData);

        const dayClasses = parsedData[dayofWeek] || [];
        console.log('Day of Week:', dayofWeek);
        console.log('Classes for Today:', dayClasses);
        console.log('Classes for Today manual:', parsedData[dayofWeek]);

        //const mealsResponse = await fetch('http://127.0.0.1:5000/api/meals&showers');
        //const mealsData = await mealsResponse.json();
  
        const personalResponse = await fetch('https://dasevu01s7.execute-api.us-east-1.amazonaws.com/FirstLaunch/personal');
        const personalData = await personalResponse.json();

        let personalEvents = [];
        if (personalData.body) {
        try {
            personalEvents = JSON.parse(personalData.body); // Safely parse body if it exists
            } catch (e) {
            console.error('Error parsing personalEvents body:', e);
            }     
        }
        console.log('Personal Events Response:', personalEvents);

        const schoolResponse = await fetch('https://dasevu01s7.execute-api.us-east-1.amazonaws.com/FirstLaunch/school');
        const schoolData = await schoolResponse.json();

        let schoolEvents = [];
        if (schoolData.body) {
            try {
                schoolEvents = JSON.parse(schoolData.body); // Safely parse body if it exists
            } catch (e) {
                console.error('Error parsing schoolEvents body:', e);
            }
        }
        console.log('School Events Response:', schoolEvents);
  
        // Process and combine data
        const combinedEvents = [...personalEvents, ...schoolEvents];
        
        processData(dayClasses, combinedEvents);

        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    // Call the function to fetch data and update rectangles
    fetchDataAndUpdateRectangles();
  }, [currentDay]);
  

  return (
    <div className="App">
      <div className="larger-header">
        <h1>Jack's Schedule {currentDate}</h1>
      </div>
      <div className="h1">
        <h1> Today is {currentDay}</h1>
      </div>
      <div className="h1">
        <h2>Classes</h2>
      </div>
      <div className="classes">
        {rectangles.filter(rect => rect.type === 'class').length > 0 ? (
          rectangles.filter(rect => rect.type === 'class').map((rect, index) => (
            <div key={index} className="rectangle" style={{ marginTop: '10px' }}>
              <strong>Class:</strong> {rect.class} <br />
              <strong>Start Time:</strong> {rect.start} <br />
              <strong>End Time:</strong> {rect.end} <br />
            </div>
          ))
        ) : (
          <p>No classes for today.</p>
        )}
      </div>

      <div className="h1">
        <h2>Events</h2>
      </div>
      <div className="events">
        {rectangles.filter(rect => rect.type === 'event').length > 0 ? (
          rectangles.filter(rect => rect.type === 'event').map((rect, index) => (
            <div key={index} className="rectangle" style={{ marginTop: '10px' }}>
              <strong>Event:</strong> {rect.event} <br />
              <strong>Start Time:</strong> {rect.start} <br />
              <strong>End Time:</strong> {rect.end} <br />
              <strong>Source:</strong> {rect.calendar} <br />
            </div>
          ))
        ) : (
          <p>No events for today.</p>
        )}
      </div>

      <div className="assignments">
        <h2>Assignments</h2>
        <ul>
          {assignments.map((item, index) => (
            <li key={index}>
              <strong>Class:</strong> {item.Column_A} <br />
              <strong>Task:</strong> {item.Column_B} <br />
              <strong>Due Date:</strong> {item.Column_C} <br />
              <strong>Time to complete:</strong> {item.Column_E} <br />
              <strong>Notes:</strong> {item.Column_F}
            </li>
          ))}
        </ul>
      </div>
      
      <div className="available-times">
        <h2>Available Time Slots</h2>
        {availableTimes.length > 0 ? (
          availableTimes.map((slot, index) => (
            <div key={index}>
              <strong>Available Slot:</strong> {slot.start} - {slot.end} <br />
            </div>
          ))
        ) : (
          <p>No available time slots.</p>
        )}
      </div>

      <div className="h1">
        <h2>All Day Events</h2>
      </div>
      <div className="all-day-events">
        {allDayEvents.length > 0 ? (
          allDayEvents.map((event, index) => (
            <div key={index} className="rectangle" style={{ marginTop: '10px' }}>
              <strong>Event:</strong> {event.event} <br />
              <strong>Start Time:</strong> {event.start} <br />
              <strong>End Time:</strong> {event.end} <br />
              <strong>Location:</strong> {event.location} <br />
              <strong>Description:</strong> {event.description} <br />
            </div>
          ))
        ) : (
          <p>No all-day events for today.</p>
        )}
      </div>
    </div>
  );
}

export default App;
